



















import {Component, Vue} from 'vue-property-decorator';
import PortalCollapse from "@/components/request/w9cr/PortalCollapse.vue";
import W9CRContractorItem from "@/components/request/w9cr/W9CRContractorItem.vue";
import W9CR_DTO from "@/dto/request/w9cr/W9CR_DTO";
import W9CRService from "@/services/request/W9CRService";
import W9CRContractorDTO from "@/dto/request/w9cr/W9CRContractorDTO";
import {namespace} from "vuex-class";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import {W9CRContractorStatus} from "@/constants/request/W9CRConstants";
import PhoneInput from "@/components/util/PhoneInput.vue";
import AutoComplete from "@/components/common/AutoComplete.vue";
import AutoCompleteDTO from "@/components/common/AutoCompleteDTO";
import PersonSearch from "@/components/common/PersonSearch.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import PersonDTO from "@/dto/person/PersonDTO";
import {ContractorFilter} from "@/dto/payroll/Filters";
import EmployeeService from "@/services/EmployeeService";
import {processError} from "@/utils/ComponentUtils";
import {CounterpartyType} from "@/constants/CounterpartyType";
import InternalW9CRContractor from "@/components/request/w9cr/InternalW9CRContractor.vue";

const AppModule = namespace("App");

@Component({
  computed: {
    AutoCompleteDTO() {
      return AutoCompleteDTO
    }
  },
  components: {
    InternalW9CRContractor,
    PortalCheckbox,
    PersonSearch,
    AutoComplete, PhoneInput, PortalInput, SingleFileHolder, W9CRContractorItem, PortalCollapse}
})
export default class EmployeeW9CR extends Vue {


  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  request: W9CR_DTO | null = null;

  mounted(){
    this.loadRequest();
  }

  loadRequest(){
    this.startLoading();
    return W9CRService.getById(this.id).then(
        ok => {
          this.request = ok.data;
          this.request.contractors = this.request?.contractors.map(c => new W9CRContractorDTO(c)).sort((a,b) => a.id - b.id)
          this.stopLoading();
        },
        err => {
          console.log(JSON.stringify(err));
          this.stopLoading();
        }
    )
  }

  get id(){
    return Number.parseInt(this.$route.params.id);
  }

  confirm(c: W9CRContractorDTO){
    c.status = W9CRContractorStatus.COMPLETED;
    this.startLoading();
    W9CRService.saveContractorByEmployee(c).then(
        ok => {
          this.stopLoading();
          this.loadRequest();
        },
        err => {
          console.log(JSON.stringify(err));
          this.stopLoading();
        }
    )
  }

}
